import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

const SectionGroup = styled.div`
  /* background: url(${props => props.image});
  background-size: cover; */
  background-color: #293A7E;
  color: white;
  display: grid;
  grid-gap: 20px;
  position:relative;
  padding-top:50px;
  padding-bottom:50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 720px) {
    grid-gap: 50px;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
`

const SectionButton = styled.div`
  margin: 0 auto;
  a {
    color: black;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 9px 20px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;
  }
`
const SectionTtitleGroup = styled.div`
  display: grid;
  margin: 0 40px;
  text-align: center;
  background-color: (${props => props.image});

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`
const SectionTitle = styled.h3`
  color: white;
  margin: 0;
  font-size: 60px;
  line-height: 1.2;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`
const SectionText = styled.p`
  color: white;
  text-align: center;
`

const Section = props => (
  <SectionGroup>
    <SectionTtitleGroup>
      <SectionTitle> {props.titlefirstoption} </SectionTitle>
      <SectionText>{props.textfirstoption}</SectionText>
      <SectionButton>
        <LocalizedLink to={props.link1}>
          <FormattedMessage id={props.buttonfirstoption} />
        </LocalizedLink>
      </SectionButton>
    </SectionTtitleGroup>
    <SectionTtitleGroup>
      <SectionTitle> {props.titlesecondoption} </SectionTitle>
      <SectionText>{props.textsecondoption}</SectionText>
      <SectionButton>
        <LocalizedLink to={props.link2}>
          <FormattedMessage id={props.buttonsecondoption} />
        </LocalizedLink>
      </SectionButton>
    </SectionTtitleGroup>
  </SectionGroup>
)

export default Section
