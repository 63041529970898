import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import Card from '../components/card'
import SelectionNext from '../components/section.3.js'

import Layout from '../components/Layout'
import LocalizedLink from '../components/LocalizedLink'

const ServicesPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="Services" />
        </h1>
      </div>
    </div>
    <div className="Cards">
      <h2>
        <FormattedMessage id="ourServices" />
      </h2>
      <p className="IntroServices">
        <FormattedMessage id="OurServicesIntro" />
      </p>
      <div className="CardListGroup">
        <Card
          title={<FormattedMessage id="ntoulapa" />}
          text={<FormattedMessage id="ntoulapatext" />}
          image={require('../Images/wardrobe.svg')}
        />

        <Card
          title={<FormattedMessage id="refrigerator" />}
          text={<FormattedMessage id="refrigeratortext" />}
          image={require('../Images/refrigerator.svg')}
        />

        <Card
          title={<FormattedMessage id="electrichob" />}
          text={<FormattedMessage id="electrichobtext" />}
          image={require('../Images/switch.svg')}
        />

        <Card
          title={<FormattedMessage id="tv" />}
          text={<FormattedMessage id="tvtext" />}
          image={require('../Images/computer-screen.svg')}
        />

        <Card
          title={<FormattedMessage id="wifi" />}
          text={<FormattedMessage id="wifitext" />}
          image={require('../Images/wifi-connection-signal-symbol.svg')}
        />

        <Card
          title={<FormattedMessage id="aircondition" />}
          text={<FormattedMessage id="airconditiontext" />}
          image={require('../Images/air-conditioner.svg')}
        />

        <Card
          title={<FormattedMessage id="hairdryer" />}
          text={<FormattedMessage id="hairdryertext" />}
          image={require('../Images/hair-dryer.svg')}
        />

        <Card
          title={<FormattedMessage id="safebox" />}
          text={<FormattedMessage id="safeboxtext" />}
          image={require('../Images/safety-box.svg')}
        />

        <Card
          title={<FormattedMessage id="bicyclesspot" />}
          text={<FormattedMessage id="bicyclesspottext" />}
          image={require('../Images/bicycle.svg')}
        />

        <Card
          title={<FormattedMessage id="pillowmenu" />}
          text={<FormattedMessage id="pillowmenutext" />}
          image={require('../Images/pillows.svg')}
        />

        <Card
          title={<FormattedMessage id="towel" />}
          text={<FormattedMessage id="toweltext" />}
          image={require('../Images/towels.svg')}
        />

        <Card
          title={<FormattedMessage id="linenreplacement" />}
          text={<FormattedMessage id="linenreplacementtext" />}
          image={require('../Images/sheets.svg')}
        />
        <Card
          title={<FormattedMessage id="laptop" />}
          text={<FormattedMessage id="laptoptext" />}
          image={require('../Images/laptop.svg')}
        />

        <Card
          title={<FormattedMessage id="kidsbed" />}
          text={<FormattedMessage id="kidsbedtext" />}
          image={require('../Images/bedkids.svg')}
        />

        <Card
          title={<FormattedMessage id="bags" />}
          text={<FormattedMessage id="bagstext" />}
          image={require('../Images/baggages.svg')}
        />

        <Card
          title={<FormattedMessage id="parking" />}
          text={<FormattedMessage id="parkingtext" />}
          image={require('../Images/parking.svg')}
        />
        <Card
          title={<FormattedMessage id="phonecenter" />}
          text={<FormattedMessage id="phonecentertext" />}
          image={require('../Images/phone-call.svg')}
        />
        <Card
          title={<FormattedMessage id="reception" />}
          text={<FormattedMessage id="receptiontext" />}
          image={require('../Images/reception.svg')}
        />
      </div>
    </div>

    <SelectionNext
      titlefirstoption={<FormattedMessage id="OurRooms" />}
      textfirstoption={<FormattedMessage id="OurRoomsIntro" />}
      buttonfirstoption={<FormattedMessage id="Checktherooms" />}
      link1="/rooms"
      titlesecondoption={<FormattedMessage id="Location" />}
      textsecondoption={<FormattedMessage id="OurLocationIntro" />}
      buttonsecondoption={<FormattedMessage id="Checkthelocation" />}
      link2="/location"
    />
  </Layout>
)

ServicesPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default ServicesPage
