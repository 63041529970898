import React from 'react'
import styled from 'styled-components'

const CardGroup = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  display: grid;
  background-color: white;
  grid-template-rows: 1fr 20px 100px;
  align-items: center;

  img {
    height: 100px;
    margin: 0 auto;
    text-align: center;
  }

  h3 {
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin: 0 auto;
    text-align: center;
    margin-top: 40px;
  }
`

const Card = props => (
  <CardGroup>
    <img src={props.image} />
    <h3>{props.title}</h3>
    <p>{props.text}</p>
  </CardGroup>
)

export default Card
